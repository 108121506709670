/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./layout.css"
import Seo from './seo';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiHome {
        nodes {
          headerImage {
            publicURL
          }
          logo {
            publicURL
          }
        }
      }
    }
  `)


  let headerImageURL = `url(${data.allStrapiHome.nodes[0].headerImage.publicURL})`;
  let logoURL = `${data.allStrapiHome.nodes[0].logo.publicURL}`;
  return (
    <div>
      <Seo />
      <header
        style={{
          background: headerImageURL,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          margin: "0",
          height: "18rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          WebkitBoxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          MozBoxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <div
          style={{
            width: "fit-content",
          }}
        >
          <img
            src={logoURL}
            style={{
              height: "200px",
              background: "white",
              borderRadius: "100px",
              margin: "0",
              boxShadow:
                "0 4px 20px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              WebkitBoxShadow:
                "0 4px 20px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              MozBoxShadow:
                "0 4px 20px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
      </header>
      <nav
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/"
        >
          Home
        </Link>
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/departments"
        >
          Departments
        </Link>
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/physicians"
        >
          Physicians
        </Link>
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/gallery"
        >
          Gallery
        </Link>
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/contact"
        >
          Contact Us
        </Link>
        <Link
          className="nav-element"
          activeClassName="nav-element-active"
          to="/news"
        >
          News
        </Link>
      </nav>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main
          style={{
            width: "100%",
            padding: "60px 40px",
            borderRadius: "20px",
            background: "#f1f1f1",
            margin: "0",
            marginTop: "20px",
            boxShadow:
              "0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            WebkitBoxShadow:
              "0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            MozBoxShadow:
              "0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          {children}
        </main>
        <footer
          style={{
            marginTop: `3rem`,
            textAlign: "center",
            fontSize: "11pt"
          }}
        >
          © {new Date().getFullYear()} Copyrights Reserved
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
